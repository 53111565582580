<template>
  <div class="card text-left">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Edit Event</h3>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form novalidate @submit.prevent="submitHandler()">
        <div class="pl-lg-4">
          <div class="row">
            <div
                v-for="(field, i) in schema.info"
                :key="i"
                :class="field.colClass"
            >
              <div class="form-group bg-silver p-2 rounded">
                <template v-if="field.type === 'checkbox'">
                  <span class="form-control-label">{{ field.label }}</span>
                  <br />
                  <label class="custom-toggle">
                    <input
                        v-model="form[field.model]"
                        :required="field.required"
                        type="checkbox"
                    />
                    <span
                        class="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                    ></span>
                  </label>
                </template>

                <template v-else-if="field.type === 'select'">
                  <label :for="field.label" class="form-control-label">{{
                      field.label
                    }}</label>
                  <select
                      v-model="form[field.model]"
                      :class="{ 'is-invalid': errors[field.model] }"
                      :multiple="field.multiple"
                      :required="field.required"
                      :size="field.size"
                      class="form-control"
                  >
                    <option
                        v-for="(option, i) in getDropdowns[field.options]"
                        :key="i"
                        :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </template>

                <template v-else-if="field.type === 'image'">
                  <label class="form-control-label"
                  >{{ field.label }}
                    <small
                    >(<a :href="form[field.model]" target="_blank">view</a
                    >)</small
                    ></label
                  >
                  <div class="custom-file">
                    <input
                        :id="field.label"
                        :required="field.required"
                        class="custom-file-input"
                        type="file"
                        @change="handleFileUpload($event, field.model)"
                    />
                    <label :for="field.label" class="custom-file-label">{{
                        field.label
                      }}</label>
                  </div>
                </template>

                <template v-else-if="field.type === 'ckeditor'">
                  <label class="form-control-label required">{{
                      field.label
                    }}</label>
                  <editor
                      class="form-control"
                      :id="`ckeditor-${field.model}`"
                      v-model="form[field.model]"
                      :api-key="ckeditor.API_KEY"
                      :init="ckeditor.config"
                  >
                  </editor>
                </template>

                <template v-else-if="field.type === 'tokenize'">
                  <label :for="field.label" class="form-control-label">{{
                      field.label
                    }}</label>
                  <multiselect
                      v-model="form[field.model]"
                      :clear-on-select="false"
                      :close-on-select="false"
                      :multiple="true"
                      :options="getDropdowns[field.options]"
                      :preselect-first="true"
                      :preserve-search="true"
                      label="label"
                      placeholder="Select Countries"
                      track-by="label"
                      @select="handleSelect($event, form[field.model])"
                  >
                    <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                    >
                      <span
                          v-if="values.length &amp;&amp; !isOpen"
                          class="multiselect__single"
                      >{{ values.map((p) => p.label).join(",") }}</span
                      >
                    </template>
                  </multiselect>
                </template>

                <template v-else-if="field.type === 'time'">
                  <label :for="field.label" class="form-control-label">{{
                      field.label
                    }}</label>
                  <input
                      :id="field.label"
                      v-model="form[field.model]"
                      :class="{ 'is-invalid': errors[field.model] }"
                      :required="field.required"
                      :type="field.type"
                      step="1"
                      class="form-control"
                  />
                </template>

                <template v-else>
                  <label :for="field.label" class="form-control-label">{{
                      field.label
                    }}</label>
                  <input
                      :id="field.label"
                      v-model="form[field.model]"
                      :class="{ 'is-invalid': errors[field.model] }"
                      :required="field.required"
                      :type="field.type"
                      class="form-control"
                  />
                </template>
              </div>
            </div>
          </div>

          <!-- <pre>{{ form.categories }}</pre> -->
          <h3>Event Categories</h3>
          <div class="bg-silver2 p-3 rounded mb-3 border">
            <TransitionGroup name="fade">
              <div
                  class="row"
                  v-for="(categoryStartTime, catTimeIndex) in form.categories"
                  :key="catTimeIndex"
              >
                <div class="form-group col-lg-12" style="margin-bottom: 5px;">
                  <label class="mt-4 font-weight-bold">{{
                      categoryStartTime.Label
                    }}</label>
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Start Date</label>
                  <input
                      v-model.lazy="form.categories[catTimeIndex].date"
                      type="date"
                      class="form-control"
                      was-validated
                      :min="todayDate()"
                      ref="catStartDate"
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Start Time</label>
                  <input
                      v-model.lazy="form.categories[catTimeIndex].time"
                      type="time"
                      class="form-control"
                      step="1"
                      was-validated
                      ref="catStartTime"
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Start Spline Index</label>
                  <input
                      v-model.lazy="
                      form.categories[catTimeIndex].StartSplineIndex
                    "
                      type="text"
                      class="form-control"
                      was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Spline Path</label>
                  <input
                      v-model.lazy="form.categories[catTimeIndex].SplinePath"
                      type="text"
                      class="form-control"
                      was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label"
                  >Start Spline Distance</label
                  >
                  <input
                      v-model.lazy="
                      form.categories[catTimeIndex].StartSplineDistance
                    "
                      type="text"
                      class="form-control"
                      was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Total Distance</label>
                  <input
                      v-model.lazy="form.categories[catTimeIndex].TotalDistance"
                      type="text"
                      class="form-control"
                      was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Elevation</label>
                  <input
                      v-model.lazy="form.categories[catTimeIndex].Elevation"
                      type="text"
                      class="form-control"
                      was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Direction</label>
                  <select
                      class="form-control"
                      v-model.number="
                      form.categories[catTimeIndex].StartCheckpointDirection
                    "
                  >
                    <option
                        v-for="(option, i) in direction"
                        :key="i"
                        :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
              </div>
            </TransitionGroup>
          </div>

          <!-- Event Winners Section -->

          <h3>Event Winners</h3>
          <div
              v-for="(winnerSection, sectionIndex) in form.winners"
              :key="sectionIndex"
              class="bg-silver2 p-3 rounded mb-3 border"
          >
            <!-- Delete Winner Section Button -->
            <button
                class="btn btn-sm btn-danger float-right"
                type="button"
                @click="deleteWinnerSection(sectionIndex)"
            >
              Delete Winner Section
            </button>
            <div class="clearfix"></div>

            <!-- Event Category and Heading -->
            <div class="row">

              <div class="col-lg-4">
                <label>Event Category</label>
                <select
                    v-model="winnerSection.event_category"
                    class="form-control"
                >
                  <option v-for="(option, index) in getDropdowns.eventCategoryDropdown" :key="index" :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div class="col-lg-4">
                <label>Gates</label>
                <select
                    v-model.lazy="winnerSection.gate_id"
                    @change="handleGateSelection(sectionIndex)"
                    class="form-control"
                >
                  <option value="">None</option>
                  <option
                      v-for="(gate, index) in gateOptions"
                      :key="index"
                      :value="gate.gate_id"
                  >
                    {{ gate.gate_name }}
                  </option>
                </select>
              </div>

              <div class="col-lg-4">
                <label>Heading</label>
                <input
                    type="text"
                    v-model="winnerSection.heading"
                    class="form-control"
                />
              </div>
            </div>

            <!-- Dynamic Winner Inputs -->
            <div class="row mt-3">
              <div
                  v-for="(winner, winnerIndex) in winnerSection.dynamicWinners"
                  :key="winnerIndex"
                  class="col-lg-4"
              >
                <label>Winner {{ winnerIndex + 1 }}</label>
                <div class="d-flex align-items-center">
                  <input
                      type="text"
                      v-model="winnerSection.dynamicWinners[winnerIndex]"
                      class="form-control"
                  />
                  <button
                      class="btn btn-sm btn-danger ml-2"
                      type="button"
                      @click="removeWinnerFromSection(sectionIndex, winnerIndex)"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>

            <!-- Add Winner Button -->
            <button
                class="btn btn-sm btn-outline-primary mt-3"
                type="button"
                @click="addWinnerToSection(sectionIndex)"
            >
              Add Winner
            </button>
          </div>

          <!-- Add New Winner Section Button -->
          <div class="text-right">
            <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                @click="addWinnerSection"
            >
              Add New Winner Section
            </button>
          </div>


        </div>

        <div class="pl-lg-4">
          <button class="btn btn-primary" type="submit">Update</button>
          <div v-if="invalidDateTimeMessage" class="text-danger">
            {{ invalidDateTimeMessage }}
          </div>
          <div v-if="invalidTeamSizeMessage" class="text-danger">
            {{ invalidTeamSizeMessage }}
          </div>
        </div>

        <div
            v-if="getServerErrors"
            class="alert alert-danger rounded mt-2"
            v-html="getServerErrors"
        ></div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Schema from "@/store/in-memory-db/forms/event.json";
import Countries from "@/json/countries.json";
import Genders from "@/json/genders.json";
import eventTypes from "@/json/eventTypes.json";
import Editor from "@tinymce/tinymce-vue";
import CkConfig from "@/json/ckEditor.js";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "AdminPagesEdit",
  inject: ["dropdowns"],
  components: {
    Multiselect,
    editor: Editor,
  },

  data() {
    return {
      schema: Schema,
      editors: {},
      direction: [
        { label: "Forward", value: "1" },
        { label: "Backward", value: "-1" },
      ],
      images: [],
      invalidDateTimeMessage: null,
      invalidTeamSizeMessage: null,
      schemaWinner: {
        winners: [
          { type: "select", label: "Event Category", model: "category", options: "eventCategoryDropdown", colClass: "col-lg-4", required: true },
          { type: "text", label: "Heading", model: "heading", colClass: "col-lg-4", required: false },
        ],
      },
      form: {
        id: null,
        // serverEventId: null,
        winners: [],
        dob_required: false,
      },
      ckeditor: CkConfig(),
    };
  },

  computed: {
    ...mapGetters("event", ["event"]),
    ...mapGetters(["errors"]),
    ...mapGetters("routes", ["getRoutes"]),

    getServerErrors() {
      if (!this.errors) return false;
      return Object.values(this.errors).join("<br>");
    },

    categories() {
      if (!this.dropdowns.categories) return;
      const categories = this.dropdowns.categories.map(cat => {
        return {
          "value": 'category-'+cat.CategoryId,
          "label": cat.Label
        }
      });

      categories.unshift({ value: "", label: "All Category" });
      categories.push({ value: "open", label: "Open" });
      return categories;
    },

    getDropdowns() {
      return {
        eventTypeDropdown: eventTypes,
        eventCategoryDropdown: this.categories,
        genderDropdown: Genders,
        routeDropdown: {},
        countriesDropdown: Countries,
      }
    },

    gateOptions() {
      if (this.event?.gates && Array.isArray(this.event.gates)) {
        return this.event.gates.map((gate) => ({
          gate_id: gate.GateId,
          gate_name: gate.GateName,
        }));
      }
      return [];
    }
  },

  mounted() {
    Promise.all([
      this.$store.dispatch("event/event", this.$route.params.id),
      this.getRoutes.length === 0 ? this.$store.dispatch("routes/loadRoutes") : Promise.resolve(),
    ])
        .then(([event]) => {
          const eventData = event.data;

          // Process winners
          this.form.winners = eventData.winners.map((winner) => ({
            event_category: winner.event_category || "",
            heading: winner.heading || "",
            dynamicWinners: this.extractDynamicWinners(winner),
            gate_id: winner.gate_id || "",
            gate_name: winner.gate_name || "",
          }));



          // Set countries
          this.form.countries = eventData.isNew
              ? this.getDropdowns.countriesDropdown
              : this.getFilteredCountriesDropdown();

          // Set default `dob_required`
          this.form.dob_required = eventData?.dob_required || false;

          // Assign routes
          this.getDropdowns.routeDropdown = this.getRoutes;
        })
        .catch((error) => {
          console.error("Error in mounted hook:", error);
        });
  },

  watch: {
    event: function(val) {
      this.form = Object.assign({}, val);
    },
    'form.winners': {
      handler(newWinners, oldWinners) {
        // Compare the new winners with the old winners to prevent unnecessary updates
        if (JSON.stringify(newWinners) !== JSON.stringify(oldWinners)) {
          this.form.winners = newWinners.map(winner => ({
            ...winner,
            gate_id: winner.gate_id || "",
            gate_name: winner.gate_name || "",
            dynamicWinners: winner.dynamicWinners || [],
            event_category: winner.event_category || "",
            heading: winner.heading || "",
          }));
        }
      },
      deep: true, // Ensure deep watching for nested changes
      immediate: true, // Trigger immediately to handle initial state
    }
  },

  methods: {
    // Helper to extract dynamic winners
    extractDynamicWinners(winner) {
      return Object.keys(winner)
          .filter((key) => key.startsWith("winner_"))
          .map((key) => winner[key]);
    },

    // Helper to filter countries dropdown
    getFilteredCountriesDropdown() {
      return this.getDropdowns.countriesDropdown.filter((x) =>
          this.form.countries.includes(x.value)
      );
    },
    handleGateSelection(sectionIndex) {
      const selectedGate = this.gateOptions.find(
          (gate) => gate.gate_id === this.form.winners[sectionIndex].gate_id
      );

      console.log(selectedGate);

      if (selectedGate) {
        this.form.winners[sectionIndex].gate_name = selectedGate.gate_name;
      } else {
        this.form.winners[sectionIndex].gate_name = "";
      }
    },
    handleSelect(event, model) {
      if (model && event.value === "SA") {
        // select all
        for (let item of this.getDropdowns.countriesDropdown) {
          if (
              item.value !== "SA" &&
              !model.filter((x) => x.value === item.value).length
          ) {
            if (item.value !== "SA") {
              model.push(item);
            }
          }
        }
      }
    },

    // Add a new winner section
    addWinnerSection() {
      this.form.winners.push({
        category: "",
        heading: "",
        dynamicWinners: Array.from({ length: 12 }, () => ""),
        gate_id: "",
        gate_name: "",
      });
    },

    // Delete a winner section
    deleteWinnerSection(sectionIndex) {
      this.form.winners.splice(sectionIndex, 1);
    },

    // Add a new winner to an existing section
    addWinnerToSection(sectionIndex) {
      this.form.winners[sectionIndex].dynamicWinners.push("");
    },

    // Remove a specific winner from a section
    removeWinnerFromSection(sectionIndex, winnerIndex) {
      this.form.winners[sectionIndex].dynamicWinners.splice(winnerIndex, 1);
    },

    handleFileUpload(event, name) {
      this.form[name] = event.target.files[0];
    },

    async submitHandler() {
      this.invalidDateTimeMessage = null;
      this.invalidTeamSizeMessage = null;

      // Validate Team Size Limit
      if (this.form.teamCalculationSize > this.form.teamSize) {
        this.invalidTeamSizeMessage =
            "Team calculation size should be less than or equal to Team size limit.";
        return;
      }

      // Prepare Form Data
      const formData = new FormData();


      Object.keys(this.form).forEach((key) => {
        if (key === "winners") {

          // Process each winner section
          this.form.winners.forEach((winnerSection, sectionIndex) => {
            formData.append(
                `winners[${sectionIndex}][event_category]`,
                winnerSection.event_category || ""
            );
            formData.append(
                `winners[${sectionIndex}][heading]`,
                winnerSection.heading || ""
            );
            formData.append(
                `winners[${sectionIndex}][gate_id]`,
                winnerSection.gate_id || ""
            );
            formData.append(
                `winners[${sectionIndex}][gate_name]`,
                winnerSection.gate_name || ""
            );

            // Add dynamic winners
            winnerSection.dynamicWinners.forEach((winner, winnerIndex) => {
              formData.append(
                  `winners[${sectionIndex}][winner_${winnerIndex + 1}]`,
                  winner || ""
              );
            });
          });

        }
        else if (key === "categories") {
          this.form.categories.forEach((category, categoryIndex) => {
            Object.keys(category).forEach((categoryKey) => {
              formData.append(
                  `categories[${categoryIndex}][${categoryKey}]`,
                  category[categoryKey]
              );
            });
          });
        }
        else if (key === "countries") {
          // Convert countries to a comma-separated string or maintain array
          formData.append(
              key,
              this.form[key].map((x) => x.value).join(",")
          );
        }
        else {
          // // Match the current key to its schema definition
          // const fieldSchema = this.schema.info.find((field) => field.model === key);
          // // Normalize checkboxes
          // if (fieldSchema && fieldSchema.type === "checkbox") {
          //   formData.append(key, this.form[key] === true ? true : false);
          // } else {
          //   formData.append(key, this.form[key] || "");
          // }

          // Match the current key to its schema definition
          const fieldSchema = this.schema.info.find((field) => field.model === key);

          // Normalize checkboxes and ensure values like 0 are included
          const fieldValue = this.form[key];
          if (fieldSchema && fieldSchema.type === "checkbox") {
            formData.append(key, fieldValue === true ? true : false);
          } else {
            formData.append(key, fieldValue !== undefined ? fieldValue : "");
          }
        }
      });

      // Submit the Form
      try {
        const res = await this.$store.dispatch("event/update", formData);
        if (res?.status) {
          this.$router.push({ name: "AdminEventsList" });
        }
      } catch (error) {
        console.error("Error during submission:", error);
      }
    }
    ,


    todayDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
  },
};
</script>

<style scoped>
.error-12 {
  font-size: 12px;
}

.bg-silver {
  background-color: #e7e7e7;
}

.bg-silver2 {
  background-color: #f7f7f7;
}

.form-control-label.required::after {
  content: " (required *)";
  color: red;
  font-weight: normal;
}
</style>

